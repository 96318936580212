import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { appURLs } from "@/app/app-urls.mjs";
import {
  statCardsCss,
  TopStatCardsFallback,
} from "@/game-destiny2/components/Profile/TopStatCards.jsx";
import type { Activity } from "@/game-destiny2/models/model-wiki.mjs";
import type { MapStatsDisplay } from "@/game-destiny2/utils/use-aggregate-stats.mjs";
import calcKDA from "@/shared-fps/calc-kda.mjs";
import HighlightStatCard from "@/shared-fps/HighlightStatCard.jsx";
import TimescaleChart from "@/shared-fps/TimescaleChart.jsx";
import { dataIsLoaded } from "@/util/wiki.mjs";

export default memo(function MapsTopStatCards({
  mapStats,
  profileImgUri,
  activities,
}: {
  mapStats: MapStatsDisplay[];
  profileImgUri: string;
  activities: Record<string, Activity>;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  if (!mapStats || !dataIsLoaded(activities)) return <TopStatCardsFallback />;

  const stats = mapStats
    .filter((m) => m.secondsPlayed)
    .sort((aStat, bStat) => bStat.kills - aStat.kills);

  if (!stats.length) return null;

  const mostWins = stats.reduce(
    (max, s) => (max.wins > s.wins ? max : s),
    stats[0],
  );
  const highlight = {
    label: t(...mostWins.label),
    position: 1,
    stat: mostWins.wins.toLocaleString(language, {
      maximumFractionDigits: 0,
    }),
    statLabel: t("common:wins", "Wins"),
    image: profileImgUri && `${appURLs.BUNGIE}/${profileImgUri}`,
    imageSize: 100,
  };

  const timeScaleData = stats
    .slice(0, 10)
    .map(({ activityReferenceId, label, ...stats }) => {
      const kills = stats.kills;
      const kda = calcKDA(kills, stats.deaths, stats.assists, 1);
      const pkda = calcKDA(
        stats.precisionKills,
        stats.deaths,
        stats.assists,
        1,
      );
      return {
        key: activityReferenceId,
        tooltipTitle: <div>{t(...label)}</div>,
        stats: {
          kills: {
            value: kills,
            display: kills.toLocaleString(language),
          },
          assists: {
            value: stats.assists,
            display: stats.assists.toLocaleString(language),
          },
          deaths: {
            value: stats.deaths,
            display: stats.deaths.toLocaleString(language),
          },
          precisionKills: {
            value: stats.precisionKills,
            display: stats.precisionKills.toLocaleString(language),
          },
          secondsPlayed: {
            value: stats.secondsPlayed,
            display: stats.secondsPlayed,
          },
          wins: {
            value: stats.precisionKills,
            display: stats.precisionKills.toLocaleString(language),
          },
          kda: {
            value: kda,
            display: kda.toLocaleString(language),
          },
          pkda: { value: pkda, display: pkda.toLocaleString(language) },
        },
      };
    });
  const kda = {
    id: "kda",
    label: ["common:stats.kda", "KDA"] as Translation,
    position: 0,
    stat1Label: ["common:stats.kda", "KDA"] as Translation,
    stat2Label: ["common:precisionKDA", "Precision KDA"] as Translation,
    color: "var(--red)",
    data: timeScaleData,
    xField: "secondsPlayed",
    yField: "kda",
    y2Field: "pkda",
    hidePosition: true,
  };
  const kills = {
    id: "kills",
    label: ["common:stats.kills", "Kills"] as Translation,
    position: 0,
    stat1Label: ["common:stats.kills", "Kills"] as Translation,
    stat2Label: ["common:precisionKills", "Precision Kills"] as Translation,
    color: "var(--orange)",
    data: timeScaleData,
    xField: "secondsPlayed",
    yField: "precisionKills",
    y2Field: "kills",
    hidePosition: true,
  };
  return (
    <div className={statCardsCss()}>
      {React.createElement(HighlightStatCard, highlight)}
      {React.createElement(TimescaleChart, kda)}
      {React.createElement(TimescaleChart, kills)}
    </div>
  );
});
